import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "Resources",
    rowProps: { noGutters: true },
    moduleComponentName: "DigitalEISPage",
    moduleProps: {
      content: {
        title: "Skip to Section",
        sectionHeadingProps: {
          heading: {
            priority: 2,
            style: {
              fontWeight: "bold",
              width: "100%",
            },
            text: "Resources",
          },
          separator: {
            style: {
              width: "100%",
            },
          },
          showClosedSubmissionAlert: true,
        },
        topics: [
          {
            id: "flight-path-proposals-section",
            title: "Flight path proposals",
            scrollToTop: false,
            content: {
              elementId: "flight-path-proposals",
              hideContent: false,
            },
          },
          {
            id: "draft-eis-submissions-overview-brochure-section",
            title: "Draft EIS submissions overview brochure",
            content: {
              elementId: "draft-eis-submissions-overview-brochure",
              hideContent: false,
            },
          },
          {
            id: "noise-insulation-policy-section",
            title:
              "Draft noise insulation and property acquisition policy brochure",
            content: {
              elementId: "noise-insulation-policy",
              hideContent: false,
            },
          },
          {
            id: "facilitated-impacts-brochure-section",
            title:
              "Changes required to flight paths for other airports brochure",
            content: {
              elementId: "facilitated-impacts-brochure",
              hideContent: false,
            },
          },
          {
            id: "flight-paths-brochure-section",
            title: "Flight paths brochure",
            content: {
              elementId: "flight-paths-brochure",
              hideContent: false,
            },
          },
          {
            id: "noise-assessment-brochure-section",
            title: "Noise assessment brochure",
            content: {
              elementId: "noise-assessment-brochure",
              hideContent: false,
            },
          },
          {
            id: "videos-section",
            title: "Videos",
            content: {
              elementId: "videos-section-resources",
              hideContent: false,
            },
          },
          {
            id: "fact-sheets-section",
            title: "Fact sheets",
            content: {
              elementId: "fact-sheets",
              hideContent: false,
            },
          },
          {
            id: "previous-project-information",
            title: "Previous project information",
            content: {
              elementId: "previous-project-information",
              hideContent: false,
            },
          },
          {
            id: "community-information-session-posters",
            title: "Community information session posters",
            content: {
              elementId: "community-information-session-posters",
              hideContent: false,
            },
          },
          {
            id: "background-information-section",
            title: "Background information",
            content: {
              elementId: "background-information",
              hideContent: false,
            },
          },
        ],
      },
    },
  },
];

const ResourcesPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default ResourcesPage;
